<template lang="">
    <div class="wrapper">
        <el-breadcrumb separator="/" class="el-breadcrumb">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">资金扶持</el-breadcrumb-item>
            <el-breadcrumb-item >金融超市</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="concent">
            <!-- 需求详情 -->
            <section class="section1">
                <div class="chartView clearfix">
                    <div class="top clearfix">
                        <div class="title fl">请填写产品申请表</div>
                    </div>
                    <div class="tab-title">
                        <div class="tab-header">
                            <img src="@/assets/img/img_bt.png">
                            <p class="tit">贷款产品信息</p>
                        </div>
                        <div class="tab-content">
                            <dl>
                                <dd>产品名称</dd>
                                <dd style="font-family: MicrosoftYaHei-Bold;
                                font-size: 14px;
                                color: #0E459C;
                                letter-spacing: 0;">简式贷</dd>
                                <dd>所在地区</dd>
                                <dd>浙江温州平阳县</dd>
                            </dl>
                            <dl>
                                <dd>产品特征</dd>
                                <dd>由信保基金中心提供担保，免抵押</dd>
                                <dd>贷款期限</dd>
                                <dd>36个月</dd>
                            </dl>
                            <dl>
                                <dd>贷款额度</dd>
                                <dd>100万元</dd>
                                <dd>额度说明</dd>
                                <dd>满足小微企业生产经营过程中周转、季节性、临时性的资金需求</dd>
                            </dl>
                        </div>
                    </div> 
                    <div class="form">
                        <div class="from-deader">
                            <img src="@/assets/img/img_bt.png" alt="">
                            <p>企业信息</p>
                        </div>
                        <div class="form-content">
                            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm" style="width: 588px;">
                                <el-form-item label="企业名称" required prop="name">
                                    <el-input v-model="ruleForm.name"></el-input>
                                </el-form-item>
                                <el-form-item label="成立时间" required>
                                    <el-col >
                                      <el-form-item prop="date1">
                                        <el-date-picker type="date" placeholder="选择日期" v-model="ruleForm.date1" style="width: 100%;"></el-date-picker>
                                      </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item label="公司地址" required prop="address">
                                    <el-input v-model="ruleForm.address"></el-input>
                                </el-form-item>
                                <el-form-item label="法人代表" required prop="legal">
                                    <el-input v-model="ruleForm.legal"></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                </div>
            </section>
            
        </div>
        <el-button type="primary" @click="save()" style="margin: 20px 12px 30px 24px">提交申请</el-button>
        <el-button @click="goback">取消</el-button>
    </div>
</template>
<script>
    // 
    export default {
        name: 'demandDetails',
        data() {
            return {
                ruleForm: {
                    name: '',
                    region: '',
                    date1: '',
                    address: '',
                    legal: '',
                    delivery: false,
                    type: [],
                    resource: '',
                    desc: ''
                },
            };
        },
        methods: {
            goback() {
                this.$router.go(-1)
            },

        },
        mounted() {

        },
        created() {

        },
    };
</script>
<style lang="scss" scoped>
    .fl {
        float: left;
    }

    .fr {
        float: right;
    }

    .tc {
        text-align: center;
    }

    .clearfix::after {
        content: '';
        display: block;
        clear: both;
    }

    .wrapper {
        padding-left: 83px;
        padding-right: 83px;
        padding-bottom: 15px;
        margin: 0 auto;
        min-width: 1366px;

        section {
            margin-bottom: 16px;
        }

        .concent {
            .section1 {
                min-height: 168px;
            }

            .section2 {
                min-height: 291px;
            }

            .section3 {
                min-height: 291px;
            }
        }
    }

    

    /deep/ .el-progress__text {
        display: none;
    }

    /deep/ .el-progress-bar {
        padding-right: 0;
    }

    /deep/ .el-progress-bar__inner {
        border-radius: 0;
        background-image: linear-gradient(270deg, #024A8F 0%, #255ADA 100%);
    }

    /deep/ .el-progress-bar__outer {
        border-radius: 0;
    }

    .dataOverview .right-box .list-item {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        font-size: 14px;
        color: #666666;
        margin-bottom: 16px;
    }

    .dataOverview .right-box .list-item:nth-last-of-type(1) {
        margin-bottom: 0;
    }

    .chartView {
        padding: 16px 20px 24px;
        background-color: #fff;
    }

    .chartView .top {
        padding-bottom: 15px;
        border-bottom: 1px solid #dddddd;
        margin-bottom: 16px;
    }

    .chartView .item-box {
        width: calc((100% - 40px) * 0.33333);
        height: 176px;
        border-radius: 0px 0px 4px 4px;
        background-image: linear-gradient(180deg, #f6faff 0%, #e9f4ff 100%);
        box-sizing: border-box;
        margin-bottom: 20px;
        margin-right: 20px;
    }

    .chartView .item-box:nth-child(3n + 1) {
        margin-right: 0;
    }

    .chartView .item-box:nth-child(n + 4) {
        margin-bottom: 0;
    }

    .chartView .item-box .item-top {
        border-top: 2px solid #4393f8;
        margin-bottom: 16px;
        position: relative;
    }

    .chartView .item-box .item-top .top-bg {
        height: 0px;
        width: 172px;
        border-top: 40px solid #4393f8;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -96px;
    }


    .chartView .center p {
        line-height: 30px;
    }

    .chartView .item-box .item-bottom {
        height: 40px;
        line-height: 40px;
        padding: 0 25px;
        margin-top: 22px;
        background: #cee4ff;
        border-radius: 0px 0px 4px 4px;
    }

    .chartView .top .time {
        font-size: 14px;
        color: #666666;
        text-align: right;
        line-height: 20px;
        font-weight: 600;
    }

    .chartView .top .img {
        width: 16px;
        height: 16px;
        margin-top: 10px;
        cursor: pointer;
    }

    .chartView .item-box .item-title {
        height: 40px;
        line-height: 40px;
        font-family: MicrosoftYaHei-Bold;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        z-index: 1;
        position: relative;
    }

    .concent .title {
        font-family: 'MicrosoftYaHei-Bold';
        font-size: 26px;
        font-weight: 600;
        color: #333333;
        line-height: 36px;
        position: relative;
        padding-left: 14px;
    }

    .concent .title:before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 20px;
        background: #4393f8;
        border-radius: 3px;
        position: absolute;
        top: 10px;
        left: 0;
    }

    .tab-title{
        .tab-header{
            background: #F6FAFF;
            display: flex;
            align-items: center;
            margin-bottom:16px;
            p{
                font-family: MicrosoftYaHei-Bold;
                font-size: 14px;
                color: #0E459C;
                letter-spacing: 0;
                line-height: 14px;
            }
        }
        .tab-content{
            width: 1160px;
            dl{
                background: white;
                height: 50px;
                dd{
                    height: 48px;
                    float: left;
                    border: 1px solid #DDDDDD;
                    line-height: 48px;
                    text-indent: 14px;
                }
                dd:nth-of-type(odd){
                    width: 15%;
                    background: #FAFAFA;
                    font-family: PingFangSC-Regular;
                    color: #666666;
                }
                dd:nth-of-type(even){
                    background: white;
                }
                dd:nth-of-type(2){
                    width: 25%;
                }
                dd:nth-of-type(4){
                    width: 44.3%;
                }
            }
        }
    }

    

    .form{
        .from-deader{
            background: #F6FAFF;
            display: flex;
            align-items: center;
            margin-top:30px;
            margin-bottom:16px;
            p{
                font-family: MicrosoftYaHei-Bold;
                font-size: 14px;
                color: #0E459C;
                letter-spacing: 0;
                line-height: 14px;
            }
        }
    }
 
</style>